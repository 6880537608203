<template>
    <form @submit.stop.prevent="handleSubmit" novalidate>
        <div class="card-body">
            <div class="form-group d-flex align-items-center">
                <div>
                    <img :src="form.profile_picture" class="img-fluid img-profile rounded-circle mr-3">
                </div>
                <div>
                    <button @click="onPickFile" type="button" class="btn btn-admin d-flex align-items-center btn-outline-primary" id="upload"><i class="las la-image la-lg mr-1"></i>Change profile picture</button>
                    <input class="d-none"  ref="file" @change="handleFileObject" accept="image/*" type="file" id="file">
                </div>
            </div>
            <hr>
            <div class="form-group">
                <label>Email</label>
                <input type="text" v-model="form.email" class="form-control disabled" disabled>
            </div>
            <div class="form-group">
                <label>Fullname</label>
                <input type="text" v-model="form.name" class="form-control" placeholder="Please enter fullname" :class="{ 'is-invalid' : $v.form.name.$error }">
            </div>
            <div class="form-group">
                <label>Phone Number</label>
                <input type="text" v-model="form.phone" class="form-control" placeholder="Please enter phone number">
            </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
            <a @click="loadProfile" class="btn btn-outline-secondary btn-form mr-2">Cancel</a>
            <button type="submit" class="btn btn-primary btn-form px-">Save Changes</button>
        </div>
    </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
    data(){
        return this.initData();
    },
    mounted() {
        this.loadProfile();
    },
    validations: {
        form: {
            name: { required },
            email: { required },
        }
    },
    methods: {
        initData(){
            return {
                form:{
                    name: null,
                    email: null
                },
                file: '',
            }
        },
        async loadProfile(){
            try {
                const { data } = await axios.get(route("auth.profile.show"));
                this.form = data.data;
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            }
        },
        onPickFile () {
            this.$refs.file.click()
        },
        handleSubmit(bvModalEvt){
            bvModalEvt.preventDefault();
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.submitSave();
        },
        handleFileObject(){
            this.file = this.$refs.file.files[0];
            this.form.profile_picture = URL.createObjectURL(this.file);
        },
        async submitSave(){
            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('form', JSON.stringify(this.form));

            try {
                const { data } = await axios.post(route("auth.profile.update"), formData);
                this.$swal('Saved!', '', 'success');
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            }
        },
    }
}

</script>
