<template>
    <span>
        <a v-if="isEdit == false" class="btn btn-primary btn-sm" @click="openModal">
            <i class="fa fa-plus"></i> Add Defects
        </a>
        <a v-if="isEdit == true" class="btn btn-primary btn-sm btn-circle" @click="openEditModal">
            <i class="fa fa-edit"></i>
        </a>
        <b-modal
            :id="'defectformmodal'+ modalId"
            :title="formModal.title"
            @hidden="resetModal"
            @ok="handleSubmit"
            ok-title="Submit"
        >
            <form ref="form" @submit.stop.prevent="handleSubmit" novalidate>

                <b-form-group label="Label" label-for="label-input">
                    <b-form-input id="label-input" v-model="form.label"></b-form-input>
                </b-form-group>

                <b-form-group label="Context Helper" label-for="context_helper-input">
                    <b-form-textarea id="context_helper-input" v-model="form.context_helper"></b-form-textarea>
                </b-form-group>

            </form>
        </b-modal>
    </span>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
    props:['componentId', 'formData', 'isEdit', 'modalId'],
    data(){
        return this.initData();
    },
    validations: {
        form: {
            label: { required }
        }
    },
    methods: {
        initData(){
            return {
                form:{
                    label: null,
                    context_helper: null
                },
                formModal:{
                    title: null
                },
                language: 'en'
            }
        },
        openModal(){
            this.formModal.title = "Create Defect";
            this.$bvModal.show('defectformmodal' + this.modalId);
        },
        openEditModal(){
            this.formModal.title = "Edit Defect";
            this.form = JSON.parse(JSON.stringify(this.formData));
            this.$bvModal.show('defectformmodal' + this.modalId);
        },
        handleSubmit(bvModalEvt){
            bvModalEvt.preventDefault();
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            if(this.isEdit == true){
                this.submitUpdate();
            }else{
                this.submitSave();
            }
        },
        async submitSave(){
            try {
                const { data } = await axios.post(route("admin.houses.components.defects.store", this.componentId), {
                    form: this.form,
                    language: this.language
                });
                this.$swal('Saved!', '', 'success');
                this.$bvModal.hide('defectformmodal' + this.modalId);
                this.$emit('update-list')
            } catch (e) {
                this.$swal('Error!', '', 'error');
            }
        },
        async submitUpdate(){
            try {
                const { data } = await axios.put(route("admin.houses.components.defects.update", [this.componentId, this.formData.id]), {
                    form: this.form,
                    language: this.language
                });
                this.$swal('Updated!', '', 'success');
                this.$bvModal.hide('defectformmodal' + this.modalId);
                this.$emit('update-list')
            } catch (e) {
                this.$swal('Error!', '', 'error');
            }
        },
        resetModal(){
            this.form = this.initData().form;
        },
    }
}

</script>
