<template>
    <span>
        <div class="card-body">
            <div class="row">
                <div class="col-sm-12">
                    <p class="step-title mb-2">Step {{ wizard }} of 3</p>
                </div>
            </div>

            <template v-if="wizard == 1">
                <div class="row">
                    <div class="col-sm-12 mb-4">
                        <div class="d-flex align-items-center">
                            <h5 class="mb-0">Inspection Area Selection</h5>
                            <a href="#" data-toggle="tooltip" data-placement="right" title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.">
                                <i class="fa fa-info-circle tooltip-icon"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="form-group">
                            <label>Choose area inspection</label>
                            <div v-if="$v.form.house_area.$error" class="invalid-feedback d-block mb-2">
                                Please select area inspection
                            </div>
                            <div class="button-area-inspection">
                                <div class="row">
                                    <div class="col-sm-6" v-for="area in areas" :key="area.id">
                                        <label class="btn btn-area d-flex align-items-center p-2" :class="radioClass(form.house_area, area.id)">
                                            <input v-model="form.house_area" class="mr-2" type="radio" name="area" :value="area">
                                            <p class="mb-0"><i :class="'las la-'+area.icon+' la-lg'"></i>{{ area.name }}</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Label of area inspection</label>
                            <input v-model="form.label_house_area" type="text" class="form-control" placeholder="i.e. Main Bedroom"  :class="{ 'is-invalid' : $v.form.label_house_area.$error }">
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="wizard == 2">
                <div class="row">
                    <div class="col-sm-12 mb-4">
                        <div class="d-flex align-items-center">
                            <h5 class="mb-0">Specific Inspection Selection</h5>
                            <a href="#" data-toggle="tooltip" data-placement="right" title="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.">
                                <i class="fa fa-info-circle tooltip-icon"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="form-group">
                            <label>Choose specific area inspection</label>
                            <div v-if="$v.form.house_component.$error" class="invalid-feedback d-block mb-2">
                                Please select specific area inspection
                            </div>
                            <div class="button-area-inspection" data-toggle="buttons">
                                <div class="row">
                                    <div class="col-sm-6" v-for="component in components" :key="component.id">
                                        <label class="btn btn-area d-flex align-items-center p-2" :class="radioClass(form.house_component, component.id)">
                                            <input v-model="form.house_component" class="mr-2" type="radio" name="component" :value="component">
                                            <p class="mb-0">{{ component.name }}</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="wizard == 3">
                <div class="row">
                    <div class="col-sm-12 mb-4">
                        <div class="d-flex align-items-center">
                            <h5 class="mb-0">{{ form.house_component.name }} Inspection Details</h5>
                            <a href="#" data-toggle="tooltip" data-placement="right" :title="form.house_component.context_helper">
                                <i class="fa fa-info-circle tooltip-icon"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <div class="form-group">
                            <label>{{ form.house_component.name }} label</label>
                            <input v-model="form.label_house_component" type="text" class="form-control" placeholder="i.e. Main Bedroom Floor"  :class="{ 'is-invalid' : $v.form.label_house_component.$error }">
                        </div>
                        <div class="form-group mb-3">
                            <label>Choose specific area inspection</label>
                        </div>
                        <div class="button-inspection-details">

                            <span v-for="defect in defects" :key="defect.id" >
                            <div class="form-group mb-1">
                                <label class="btn btn-check d-flex align-items-center p-2 mb-0">
                                    <input v-model="form.house_defects" class="inspect mr-2" type="checkbox" :value="defect" @change="showUploadFile(defect.id)">
                                    <p class="mb-0">{{ defect.label }}</p>
                                </label>
                            </div>
                            <div class="form-group bg-upload mb-2 rounded" v-if="showUpload[defect.id]">
                                <input type="file" class="form-control-file upload-check p-2 show-upload" :ref="'file'+defect.id" @change="handleFileObject(defect.id)">
                            </div>
                            </span>

                        </div>
                    </div>
                </div>
            </template>

        </div>
        <div class="card-footer d-flex justify-content-end">
            <a v-if="wizard == 1" @click="cancelWizard" class="btn btn-outline-secondary btn-form mr-2">Cancel</a>
            <a v-if="wizard > 1" @click="previousPage" class="btn btn-secondary btn-form mr-2">Previous</a>
            <a v-if="wizard < 3" @click="nextPage" class="btn btn-primary btn-form">Next</a>
            <a v-if="wizard == 3" class="btn btn-primary btn-form" @click="handleSubmit">Save</a>
        </div>

    </span>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
    props: ['houseId'],
    data(){
        return this.initData();
    },
    mounted() {
        this.loadData();
    },
    validations: {
        form: {
            house_area: { required },
            house_component: { required },
            label_house_area: { required },
            label_house_component: { required }
        }
    },
    methods:{
        initData(){
            return {
                wizard: 1,
                form: {
                    house_area: null,
                    house_component: null,
                    house_defects: [],
                    label_house_area: null,
                    label_house_component: null,
                },
                areas: [],
                components: [],
                defects: [],
                showUpload: [],
                files: []
            }
        },
        async loadData(){
            const [areas, components] = await Promise.all([
                axios.get(route("owner.assessments.areas.list")),
                axios.get(route("owner.assessments.components.list")),
            ]);

            this.areas = areas.data;
            this.components = components.data;
        },
        async loadDefects(){
            try {
                const { data } = await axios.get(route("owner.assessments.components.defects.list", this.form.house_component.id));
                this.defects = data;
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            };
        },
        radioClass(form, value){
            if(form){
                if(form.id == value){
                    return 'focus active'
                }
            }
            return;
        },
        showUploadFile(key){
            if(this.showUpload[key]){
                this.showUpload[key] = !this.showUpload[key];
            }else{
                this.showUpload[key] = true;
            }
        },
        handleFileObject(key){
            const refName = `file${key}`
            this.files[key] = this.$refs[refName][0].files[0];
        },
        cancelWizard(){
            this.$emit('cancel-wizard')
        },
        handleSubmit(){
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            this.$swal({
                title: 'Confirmation',
                text: "Confirm save defect inspection",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3EC0AD',
                cancelButtonColor: '#3F3D56',
                confirmButtonText: 'Save'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let formData = new FormData();

                    this.form.house_defects.forEach(defect => {
                        formData.append('file'+defect.id, this.files[defect.id]);
                    });

                    formData.append('form', JSON.stringify(this.form));
                    try {
                        await axios.post(route("owner.assessments.store", this.houseId), formData);
                        this.$swal({
                            title: 'Success!',
                            text: 'Assessment has been created.',
                            icon: 'success',
                            didClose: () =>{
                                this.$emit('complete-wizard')
                            }
                        })
                    } catch (e) {
                        console.log(e);
                    }
                }
            });
        },
        nextPage(){
            if(this.wizard == 1){
                this.$v.form.house_area.$touch();
                this.$v.form.label_house_area.$touch();

                if (this.$v.form.house_area.$invalid || this.$v.form.label_house_area.$invalid) {
                    return;
                }
            }

            if(this.wizard == 2){
                this.$v.form.house_component.$touch();

                if (this.$v.form.house_component.$invalid) {
                    return;
                }
                this.loadDefects();
            }

            this.wizard++;
        },
        previousPage(){
            this.wizard--;
        },
    }
}
</script>

<style scoped>

.show-upload {
  display: inline-block;
}

</style>
