<template>
    <form @submit.stop.prevent="handleSubmit" novalidate>
        <div class="row mb-3">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label v-if="edit == true">Replace floor plan</label>
                    <label v-else>Upload floor plan</label>
                    <div class="custom-file">
                        <!-- MOST IMPORTANT - SEE "ref" AND "@change" PROPERTIES -->
                        <input type="file" class="custom-file-input" id="customFile" ref="file" @change="handleFileObject()">
                        <label class="custom-file-label text-left" for="customFile">{{ filename }}</label>
                    </div>
                    <a v-if="edit == true && form.floorplan != null" :href="form.floorplan" target="_blank">View Current Floor Plan</a>
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label>Status *</label>
                    <select v-model="form.house_status_id" class="form-control" :class="{ 'is-invalid' : $v.form.house_status_id.$error }">
                        <option :value="null">Please select</option>
                        <option value="1">Owner and Occupant</option>
                        <option value="2">Owner and not Occupant</option>
                        <option value="3">Other</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label>Label of house *</label>
                    <input v-model="form.label" type="text" class="form-control" placeholder="i.e. My House" :class="{ 'is-invalid' : $v.form.label.$error }">
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label>Type of house *</label>
                    <select v-model="form.house_type_id" class="form-control" :class="{ 'is-invalid' : $v.form.house_type_id.$error }">
                        <option :value="null">Please select</option>
                        <option v-for="houseType in houseTypes" :key="houseType.id" :value="houseType.id">{{ houseType.name }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label>Address line 1 *</label>
                    <input v-model="form.address.line_1" type="text" class="form-control" placeholder="" :class="{ 'is-invalid' : $v.form.address.line_1.$error }">
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label>Address line 2</label>
                    <input v-model="form.address.line_2" type="text" class="form-control" placeholder="">
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label>City *</label>
                    <input v-model="form.address.city" type="text" class="form-control" placeholder="" :class="{ 'is-invalid' : $v.form.address.city.$error }">
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label>State *</label>
                    <select v-model="form.address.state_id" class="form-control" :class="{ 'is-invalid' : $v.form.address.state_id.$error }">
                        <option :value="null">Please select</option>
                        <option v-for="state in states" :key="state.id" :value="state.id">{{ state.name }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label>Postcode *</label>
                    <input v-model="form.address.postcode" type="text" class="form-control" placeholder="" :class="{ 'is-invalid' : $v.form.address.postcode.$error }">
                </div>
            </div>
        </div>


        <div class="row mb-3">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label>Developer *</label>
                    <input v-model="form.information.developer" type="text" class="form-control" placeholder="i.e. Gamuda Land" :class="{ 'is-invalid' : $v.form.information.developer.$error }">
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label>Local authority *</label>
                    <input v-model="form.information.local_authority" type="text" class="form-control" placeholder="i.e. Dewan Bandaraya Kuala Lumpur" :class="{ 'is-invalid' : $v.form.information.local_authority.$error }">
                </div>
            </div>
        </div>

        <div class="card-footer">
            <div class="d-flex justify-content-end">
                <a :href="route('owner.houses.index')" class="btn btn-admin btn-empty px-4 mr-2">Cancel</a>
                <button type="submit" class="btn btn-admin btn-primary px-5">Save</button>
            </div>
        </div>
    </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
    props:['houseSlug', 'edit'],
    data(){
        return this.initData();
    },
    mounted() {
        this.loadHouseType();
        this.loadState();
        if(this.edit == true){
            this.loadHouse();
        }
    },
    validations: {
        form: {
            label: { required },
            house_type_id: { required },
            house_status_id: { required },
            address:{
                line_1: { required },
                city: { required },
                postcode: { required },
                state_id: { required },
            },
            information:{
                developer: { required },
                local_authority: { required },
            }
        }
    },
    methods: {
        initData(){
            return {
                form:{
                    label: null,
                    house_type_id: null,
                    house_status_id: null,
                    address: {
                        line_1: null,
                        line_2: null,
                        city: null,
                        postcode: null,
                        state_id: null,
                    },
                    information: {
                        developer: null,
                        local_authority: null,
                    },
                    floorplan: null
                },
                file: '',
                filename: '',
                houseTypes: null,
                states: null
            }
        },
        async loadHouse(){
            try {
                const { data } = await axios.get(route("owner.houses.show", this.houseSlug));
                this.form = data.data;
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            }
        },
        resetModal(){
            this.form = this.initData().form;
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        handleSubmit(bvModalEvt){
            bvModalEvt.preventDefault();
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            if(this.edit == true){
                this.submitUpdate();
            }else{
                this.submitSave();
            }
        },
        handleFileObject(){
            this.file = this.$refs.file.files[0]
            this.filename = this.file.name
        },
        async loadHouseType(){
            try {
                const { data } = await axios.get(route("owner.houses.types.list"));
                this.houseTypes = data;
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            }
        },
        async loadState(){
            try {
                const { data } = await axios.get(route("state.list"));
                this.states = data;
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            }
        },
        async submitSave(){
            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('form', JSON.stringify(this.form));

            try {
                await axios.post(route("owner.houses.store"), formData);
                this.$swal('Saved!', '', 'success');
                window.location.replace(route('owner.houses.index'));
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            }
        },
        async submitUpdate(){
            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('form', JSON.stringify(this.form));
            formData.append('_method', 'PATCH');

            try {
                await axios.post(route("owner.houses.update", this.houseSlug), formData);
                this.$swal('Saved!', '', 'success');
                // window.location.replace(route('owner.houses.index'));
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            }
        },
    }
}

</script>
