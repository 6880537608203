<template>
    <b-modal
        id="defectmodal"
        :title="formModal.title"
        @hidden="resetModal"
        size="lg"
        ok-only
    >
        <house-component-defect-form :is-edit="false" :modal-id="0" :component-id="component_id" @update-list="updateList"></house-component-defect-form>
        <b-list-group class="mt-3">
            <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="defect in defects" :key="defect.id">
                {{ defect.label }}
                <span class="pull-right">
                    <house-component-defect-form :is-edit="true" :form-data="defect" :component-id="component_id" @update-list="updateList" :modal-id="defect.id"></house-component-defect-form>
                    <a class="btn btn-danger btn-sm btn-circle" @click="deleteThis(defect.id)">
                        <i class="fa fa-trash"></i>
                    </a>
                </span>
            </b-list-group-item>
        </b-list-group>
    </b-modal>
</template>

<script>
import HouseComponentDefectForm from './HouseComponentDefectForm.vue';
export default {
  components: { HouseComponentDefectForm },
    data(){
        return this.initData();
    },
    methods: {
        initData(){
            return {
                formModal: {
                    title: null
                },
                defects: [],
                component_id: null
            }
        },
        async openModal(id){
            try {
                const { data } = await axios.get(route("admin.houses.components.defects.index", id));
                this.component_id = id;
                this.defects = data.data;
                this.formModal.title = "Defect List for Window";
                this.$bvModal.show('defectmodal');
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            }
        },
        async updateList(){
            try {
                const { data } = await axios.get(route("admin.houses.components.defects.index", this.component_id));
                this.defects = data.data;
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            }
        },
        resetModal(){
            this.form = this.initData().form;
        },
        async deleteThis(id){
             this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const { data } = await axios.delete(route("admin.houses.components.defects.destroy", [this.component_id, id] ));
                        this.$swal({
                            title: 'Deleted!',
                            text: 'House Component Defect has been deleted.',
                            icon: 'success',
                            didClose: () =>{
                                this.updateList();
                            }
                        })
                    } catch (e) {
                        console.log(e);
                    }
                }
            });
        }
    }
}

</script>
