<template>
    <b-modal
      id="houseareamodal"
      :title="formModal.title"
      @hidden="resetModal"
      @ok="handleSubmit"
      ok-title="Submit"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit" novalidate>

        <b-form-group label="Name" label-for="name-input">
            <b-form-input id="name-input" v-model="form.name" :state="validateState('name')"></b-form-input>
        </b-form-group>

        <b-form-group label="Icon" label-for="name-input">
            <b-form-input id="name-input" v-model="form.icon" :state="validateState('icon')"></b-form-input>
            <small><a href="https://icons8.com/line-awesome" target="_blank">Reference</a></small>
        </b-form-group>

        <b-form-group label="Context Helper" label-for="context_helper-input">
            <b-form-textarea id="context_helper-input" v-model="form.context_helper"></b-form-textarea>
        </b-form-group>

      </form>
    </b-modal>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
export default {
    data(){
        return this.initData();
    },
    mounted() {
        // this.openModal();
    },
    validations: {
        form: {
            name: { required },
            icon: { required },
        }
    },
    methods: {
        initData(){
            return {
                form:{
                    name: null,
                    context_helper: null,
                    icon: 'lightbulb',
                },
                formModal:{
                    title: null
                },
                isEdit: false,
                language: 'en'
            }
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        openModal(){
            this.isEdit = false;
            this.formModal.title = "Add House Area";
            this.$bvModal.show('houseareamodal');
        },
        async editModal(id){
            this.isEdit = true;
            this.formModal.title = "Edit House Area";
            try {
                const { data } = await axios.get(route("admin.houses.areas.showLang", [id, 'en']));
                this.form = data.data;
                this.$bvModal.show('houseareamodal');
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            }
            this.$bvModal.show('houseareamodal');
        },
        resetModal(){
            this.form = this.initData().form;
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        handleSubmit(bvModalEvt){
            bvModalEvt.preventDefault();
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            if(this.isEdit == true){
                this.submitUpdate();
            }else{
                this.submitSave();
            }
        },
        async submitSave(){
            try {
                const { data } = await axios.post(route("admin.houses.areas.store"), {
                    form: this.form,
                    language: this.language
                });
                this.$swal('Saved!', '', 'success');
                this.$bvModal.hide('houseareamodal');
                reloadTable();
            } catch (e) {
                this.$swal('Error!', '', 'error');
            }
        },
        async submitUpdate(){
            try {
                const { data } = await axios.put(route("admin.houses.areas.update", this.form.id), {
                    form: this.form,
                    language: this.language
                });
                this.$swal('Saved!', '', 'success');
                reloadTable();
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            }
        },
        async deleteUser(id){
             this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const { data } = await axios.delete(route("admin.houses.areas.destroy", id ));
                        this.$swal({
                            title: 'Deleted!',
                            text: 'House Area has been deleted.',
                            icon: 'success',
                            didClose: () =>{
                                reloadTable();
                            }
                        })
                    } catch (e) {
                        console.log(e);
                    }
                }
            });
        }
    }
}

</script>
