<template>
    <span>
        <template v-if="page == 'select_house'">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <label>Please select house</label>
                            <div class="input-group align-items-center">
                                <select class="form-control" v-model="selectedHouse" @change="loadAssessments">
                                    <option :value="null">Please Select</option>
                                    <option v-for="house in houses" :key="house.id" :value="house">{{ house.label }}</option>
                                </select>
                                <div class="input-group-append pl-2">
                                    <a href="#" data-toggle="tooltip" data-placement="right" title="If you do not have house profile yet, please add it on house profile page">
                                        <i class="fa fa-info-circle tooltip-icon"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5" v-if="selectedHouse == null">
                    <div class="col-sm-12 d-flex justify-content-end">
                        <img src="/img/graphics/form-bg.png" class="img-fluid form-img">
                    </div>
                </div>

                <template v-else>
                    <!-- yellow content -->
                    <div class="row mt-4">
                        <div class="col-md-12 col-sm-12">
                            <div class="card bg-home-selected">
                                <div class="card-body py-5 px-2">
                                    <div class="row align-items-center">
                                        <div class="col-md-2 col-sm-12">
                                            <img src="/img/graphics/home-img.png" class="home-img">
                                        </div>
                                        <div class="col-md-5 col-sm-12">
                                            <div class="form-group">
                                                <label class="mb-1 text-primary">House name</label>
                                                <p class="font-weight-medium mb-0">{{ selectedHouse.label }}</p>
                                            </div>
                                            <div class="form-group">
                                                <label class="mb-1 text-primary">Local authority</label>
                                                <p class="font-weight-medium mb-0">{{ selectedHouse.information.local_authority }}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-5 col-sm-12">
                                            <div class="form-group">
                                                <label class="mb-1 text-primary">House type</label>
                                                <p class="font-weight-medium mb-0">{{ selectedHouse.house_type }}</p>
                                            </div>
                                            <div class="form-group">
                                                <label class="mb-1 text-primary">State</label>
                                                <p class="font-weight-medium mb-0">{{ selectedHouse.address.state }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END: yellow content -->

                    <!-- added inspection -->
                    <div class="row mt-3">

                        <div class="col-md-6 col-sm-12" v-for="assessment in assessments" :key="assessment.id">
                            <div class="form-group border d-flex align-items-center justify-content-between rounded p-4 mb-2">
                                <div>
                                    <label class="mb-1 text-primary">Inspection label</label>
                                    <p class="font-weight-medium mb-0">{{ assessment.label_house_area }}</p>
                                </div>
                                <div>
                                    <a @click="deleteAssessment(assessment.id)">
                                        <i class="las la-trash-alt text-muted la-2x"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- add inspection -->
                    <div class="row mt-2">
                        <div class="col-md-12 col-sm-12 align-items-center">
                            <a @click="page = 'wizard'">
                                <div class="add-inspection d-flex align-items-center justify-content-center p-4">
                                    <span class="mb-0"><i class="las la-plus la-lg mr-1"></i>Add inspection area</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </template>
            </div>
        </template>

        <template v-if="page == 'wizard'">
            <assessment-wizard :house-id="selectedHouse" @cancel-wizard="cancelWizard" @complete-wizard="completeWizard"></assessment-wizard>
        </template>
    </span>
</template>

<script>
import AssessmentWizard from './AssessmentWizard.vue';
export default {
    components: { AssessmentWizard },
    data(){
        return this.initData();
    },
    mounted() {
        this.loadHouses();
    },
    methods:{
        initData(){
            return {
                selectedHouse:null,
                initSelected:null,
                houses: [],
                assessments: [],
                page: 'select_house'
            }
        },
        async loadHouses(){
            try {
                const { data } = await axios.get(route("owner.houses.list"));
                this.houses = data.data;
                if(this.initSelected){
                    this.selectedHouse = this.initSelected;
                    this.loadAssessments();
                }
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            }
        },
        initSelectHouse(house){
            this.initSelected = house;
        },
        async loadAssessments(){
            if(this.selectedHouse){
                try {
                    const { data } = await axios.get(route("owner.houses.assessments", this.selectedHouse));
                    this.assessments = data;
                } catch (e) {
                    console.log(e);
                    this.$swal('Error!', '', 'error');
                }
            }
        },
        async deleteAssessment(id){
            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await axios.delete(route("owner.assessments.destroy", id ));
                        this.$swal({
                            title: 'Deleted!',
                            text: 'Assessment has been deleted.',
                            icon: 'success',
                            didClose: () =>{
                                this.loadAssessments();
                            }
                        })
                    } catch (e) {
                        console.log(e);
                    }
                }
            });
        },
        cancelWizard(){
            this.page = this.initData().page;
        },
        completeWizard(){
            this.page = this.initData().page;
            this.loadAssessments();
        }
    }
}
</script>
