<template>
    <form class="my-5 mobile-spacing-contact" @submit="handleSubmit" novalidate>
        <div class="row">
            <div class="col-md-6 col-sm-12 mb-3">
                <input v-model="form.name" type="text" class="form-control" placeholder="Write your name" :class="{ 'is-invalid': $v.form.name.$error }">
            </div>
            <div class="col-md-6 col-sm-12 mb-3">
                <input v-model="form.email" type="email" class="form-control" placeholder="Write your email" :class="{ 'is-invalid': $v.form.email.$error }">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <textarea v-model="form.description" class="form-control" rows="6" placeholder="Write description of your request or questions"></textarea>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-12 d-flex align-items-center justify-content-between">
                <div class="form-group mb-0">
                    <div class="form-check">
                        <input v-model="form.isAgree" class="form-check-input" type="checkbox" id="gridCheck" :class="{ 'is-invalid': $v.form.isAgree.$error }">
                        <label class="form-check-label" for="gridCheck">
                        <p class="mb-0">I agree with <a href="https://squared.my/privacypolicy" class="text-secondary font-weight-bold" target="_blank">Privacy policy</a></p>
                        </label>
                        <div class="invalid-feedback">
                            You must agree before submitting.
                        </div>
                    </div>
                </div>
                <div>
                    <button type="submit" class="btn btn-primary">Submit</button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
export default {
    data(){
        return this.initData();
    },
    validations: {
        form: {
            name: { required },
            email: { required, email },
            isAgree: { checked: value => value === true }
        }
    },
    methods: {
        initData(){
            return {
                form:{
                    name: null,
                    email: null,
                    description: null,
                    isAgree: false
                }
            }
        },
        resetModal(){
            this.form = this.initData().form;
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        handleSubmit(bvModalEvt){
            bvModalEvt.preventDefault();
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.submitSave();
        },
        async submitSave(){
            try {
                const { data } = await axios.post(route("contacts.store"), {
                    form: this.form,
                });
                this.$swal('Saved!', '', 'success');
                this.resetModal();
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            }
        },
    }
}

</script>
