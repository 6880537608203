<template>
    <img class="rounded-circle" :src="image" width="25px">
</template>

<script>
export default {
    data(){
        return {
            image: null
        }
    },
    mounted(){
        this.loadImage();
    },
    methods: {
        async loadImage(){
            try {
                const { data } = await axios.get(route("auth.profile.picture"));
                this.image = data;
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            }
        }
    }
}

</script>
