<template>
    <b-modal
      id="usermodal"
      title="Submit Your Name"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleSubmit"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Name"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="user.name"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
</template>

<script>
    export default {
        data(){
            return this.initData();
        },
        mounted() {
            this.openModal();
        },
        methods: {
            initData(){
                return {
                    user:{
                        name: null
                    }
                }
            },
            openModal(){
                this.$bvModal.show('usermodal');
            },
            resetModal(){
                console.log('Reset');
            },
            handleSubmit(){
                console.log('Submit');
            }
        }
    }
</script>
