import { render, staticRenderFns } from "./HouseComponentDefect.vue?vue&type=template&id=88ae3b0a&"
import script from "./HouseComponentDefect.vue?vue&type=script&lang=js&"
export * from "./HouseComponentDefect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports