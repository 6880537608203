<script>
export default {
    props: ['houseId'],
    methods: {
        async deleteThis(){
            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        await axios.delete(route("owner.houses.destroy", this.houseId ));
                        this.$swal({
                            title: 'Deleted!',
                            text: 'House has been deleted.',
                            icon: 'success',
                            didClose: () =>{
                                window.location.replace(route('owner.houses.index'));
                            }
                        })
                    } catch (e) {
                        console.log(e);
                    }
                }
            });
        }
    }
}

</script>
