<template>
    <form @submit.stop.prevent="handleSubmit" novalidate>
        <div class="card-body px-4 py-5">
            <p>Please fill information below if you wish to change password.</p>
            <div class="form-group mb-3">
                <label>New password</label>
                <input v-model="form.password" type="password" class="form-control" placeholder="Please enter new password" :class="{ 'is-invalid' : $v.form.password.$error }">
            </div>
            <div class="form-group mb-3">
                <label>Re-enter new password</label>
                <input v-model="form.repeatPassword" type="password" class="form-control" placeholder="Please re-enter new password" :class="{ 'is-invalid' : $v.form.repeatPassword.$error }">
                <div class="invalid-feedback" v-if="!$v.form.repeatPassword.sameAsPassword">
                    Passwords must be identical.
                </div>
            </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
            <a @click="reset" class="btn btn-outline-secondary btn-form mr-2">Cancel</a>
            <button type="submit" class="btn btn-primary btn-form px-">Save Changes</button>
        </div>
    </form>
</template>

<script>
import { required, sameAs, minLength  } from "vuelidate/lib/validators";
export default {
    data(){
        return this.initData();
    },
    validations: {
        form: {
            password: {
                required,
                minLength: minLength(6)
            },
            repeatPassword: {
                sameAsPassword: sameAs('password')
            },
        }
    },
    methods: {
        initData(){
            return {
                form:{
                    password: null,
                    repeatPassword: null
                }
            }
        },
        reset(){
            this.form = this.initData().form;
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        handleSubmit(bvModalEvt){
            bvModalEvt.preventDefault();
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.submitSave();
        },
        async submitSave(){
            try {
                const { data } = await axios.post(route("auth.password.update"), {
                    form: this.form
                });
                this.$swal('Saved!', '', 'success');
                this.reset();
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            }
        },
    }
}

</script>
