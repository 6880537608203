<template>
    <b-modal
      id="usermodal"
      :title="formModal.title"
      @hidden="resetModal"
      @ok="handleSubmit"
      ok-title="Submit"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit" novalidate>

        <b-form-group label="Name" label-for="name-input">
            <b-form-input id="name-input" v-model="form.name" :state="validateState('name')"></b-form-input>
        </b-form-group>

        <b-form-group label="Email" label-for="email-input">
            <b-form-input id="email-input" v-model="form.email" :state="validateState('email')"></b-form-input>
        </b-form-group>

        <b-form-group label="Password" label-for="password-input">
            <b-form-input id="password-input" type="password" v-model="form.password" :state="validateState('password')"></b-form-input>
        </b-form-group>

        <b-form-group label="Role" label-for="role-input">
            <b-form-select id="role-input" v-model="form.role" :options="options"></b-form-select>
        </b-form-group>

      </form>
    </b-modal>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
export default {
    data(){
        return this.initData();
    },
    mounted() {
        // this.openModal();
    },
    validations() {
        if(this.isEdit){
            return {
                form: {
                    name: {
                        required,
                        minLength: minLength(4)
                    },
                    email: {
                        required,
                        email,
                    }
                }
            }
        }else{
            return {
                form: {
                    name: {
                        required,
                        minLength: minLength(4)
                    },
                    email: {
                        required,
                        email,
                    },
                    password: {
                        required,
                        minLength: minLength(6)
                    }
                }
            }
        }
    },
    methods: {
        initData(){
            return {
                form:{
                    name: null,
                    email: null,
                    password: null,
                    role: 'Admin'
                },
                options:[
                    { value: 'Admin', text: "Admin" },
                    { value: "Owner", text: "Home Owner" },
                ],
                formModal:{
                    title: null
                },
                isEdit: false,
            }
        },
        validateState(name) {
            if(this.isEdit && name == 'password'){
                return null;
            }

            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;

        },
        openModal(){
            this.isEdit = false;
            this.formModal.title = "Add User";
            this.$bvModal.show('usermodal');
        },
        async editModal(id){
            this.isEdit = true;
            this.formModal.title = "Edit User";
            try {
                const { data } = await axios.get(route("admin.users.show", id));
                this.form = data.data;
                this.$bvModal.show('usermodal');
            } catch (e) {
                this.$swal('Error!', '', 'error');
            }
            this.$bvModal.show('usermodal');
        },
        resetModal(){
            this.form = this.initData().form;
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        handleSubmit(bvModalEvt){
            bvModalEvt.preventDefault();
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            if(this.isEdit == true){
                this.submitUpdate();
            }else{
                this.submitSave();
            }
        },
        async submitSave(){
            try {
                const { data } = await axios.post(route("admin.users.store"), {
                    form: this.form,
                });
                this.$swal('Saved!', '', 'success');
                this.$bvModal.hide('usermodal');
                reloadTable();
            } catch (e) {
                this.$swal('Error!', '', 'error');
            }
        },
        async submitUpdate(){
            try {
                const { data } = await axios.put(route("admin.users.update", this.form.id), {
                    form: this.form,
                });
                this.$swal('Saved!', '', 'success');
                reloadTable();
            } catch (e) {
                console.log(e);
                this.$swal('Error!', '', 'error');
            }
        },
        async deleteUser(id){
             this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const { data } = await axios.delete(route("admin.users.destroy", id ));
                        this.$swal({
                            title: 'Deleted!',
                            text: 'User has been deleted.',
                            icon: 'success',
                            didClose: () =>{
                                reloadTable();
                            }
                        })
                    } catch (e) {
                        console.log(e);
                    }
                }
            });
        }
    }
}

</script>
